
import inputHelper from '@/helpers/Input.helper';
import { calculateMealBites, calculateMealCalories, calculateMealQuantity } from '@/helpers/NutritionHelper';
import {
  getMacroIcon,
  MacroType,
  Meal,
  MealFormDto,
  NutritionalPlan,
  NutritionalPlanFormDto,
} from '@/models/NutritionalPlan';
import { useToast } from 'primevue/usetoast';
import { computed, defineComponent, PropType, ref, Ref } from 'vue';
import { useI18n } from 'vue-i18n';
import NutritionalPlanMealConfirmModal from '../menus/NutritionalPlanMealConfirmModal.vue';
export default defineComponent({
  components: { NutritionalPlanMealConfirmModal },
  emits: ['nutritional-plan-changed'],
  props: {
    mealIndex: {
      type: Number,
      required: true,
    },
    meal: {
      type: Object as PropType<Meal>,
      required: true,
    },
    totalCalories: {
      type: Number,
      required: true,
    },
    userCanEdit: {
      type: Boolean,
      default: false,
    },
    nutritionalPlanFormDto: {
      type: Object as PropType<NutritionalPlanFormDto>,
      required: true,
    },
  },
  setup(props, { emit }) {
    const toast = useToast();
    const { t } = useI18n();

    const updateNutritionPlanMealsCardModalRef = ref();

    const editMode = ref(false);
    const mutableMeal: Ref<MealFormDto> = ref(new MealFormDto(inputHelper.createCopy(props.meal)));

    const onEditForm = () => {
      editMode.value = true;
    };

    const onSave = () => {
      if (!mealPlanCanBeUpdated.value) {
        return;
      }
      updateNutritionPlanMealsCardModalRef.value?.openDialog(mutableMeal.value);
    };

    const mealPlanCanBeUpdated = computed(() => {
      const totalShare = mutableMeal.value.mealDetails.reduce((sum, mealDetail) => sum + mealDetail.share, 0);
      return totalShare === 100;
    });

    const onCancel = () => {
      editMode.value = false;
      mutableMeal.value = new MealFormDto(inputHelper.createCopy(props.meal));
    };

    const totalCalculatedCalories = computed(() => {
      return parseFloat(((props.totalCalories * mutableMeal.value.share) / 100).toFixed(2));
    });

    function recalculateValues(newValue: any, macroType: MacroType) {
      if (newValue.value === undefined || newValue.value === null || newValue.value === '') {
        return;
      }
      mutableMeal.value.mealDetails
        .filter((mealDetail) => mealDetail.macroType === macroType)
        .forEach((details) => {
          details.calories = calculateMealCalories(Number(totalCalculatedCalories.value), newValue.value);
          details.quantity = calculateMealQuantity(details.calories, macroType);
          details.bites = calculateMealBites(details.quantity);
        });
    }

    const updatedNutritionalPlanMeal = (nutritionalPlanUpdated: NutritionalPlan) => {
      toast.add({
        severity: 'success',
        summary: `${t('messages.notifications.successEditNutritionPlan')}`,
        life: 3000,
      });
      emit('nutritional-plan-changed', nutritionalPlanUpdated);

      editMode.value = false;
    };

    return {
      updateNutritionPlanMealsCardModalRef,
      mutableMeal,
      mealPlanCanBeUpdated,
      editMode,
      onEditForm,
      onSave,
      onCancel,
      getMacroIcon,
      totalCalculatedCalories,
      recalculateValues,
      updatedNutritionalPlanMeal,
    };
  },
});
