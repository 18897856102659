import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "c-container" }
const _hoisted_2 = { class: "c-table-title-container" }
const _hoisted_3 = { class: "c-table-container" }
const _hoisted_4 = { "aria-label": '' }
const _hoisted_5 = { class: "row-purple" }
const _hoisted_6 = { class: "title" }
const _hoisted_7 = { class: "row-purple" }
const _hoisted_8 = { class: "row-purple" }
const _hoisted_9 = { class: "row-purple" }
const _hoisted_10 = { class: "row-purple" }
const _hoisted_11 = { class: "title" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("h4", null, _toDisplayString(_ctx.$t('nutrition.foodDistribution.totals')), 1)
    ]),
    _createElementVNode("div", _hoisted_3, [
      _createElementVNode("table", _hoisted_4, [
        _createElementVNode("thead", null, [
          _createElementVNode("tr", null, [
            _cache[0] || (_cache[0] = _createElementVNode("th", null, null, -1)),
            _createElementVNode("th", null, _toDisplayString(_ctx.$t('nutrition.foodDistribution.kcal')), 1),
            _createElementVNode("th", null, _toDisplayString(_ctx.$t('nutrition.foodDistribution.p')), 1),
            _createElementVNode("th", null, _toDisplayString(_ctx.$t('nutrition.foodDistribution.g')), 1),
            _createElementVNode("th", null, _toDisplayString(_ctx.$t('nutrition.foodDistribution.ch')), 1)
          ])
        ]),
        _createElementVNode("tbody", null, [
          _createElementVNode("tr", _hoisted_5, [
            _createElementVNode("td", _hoisted_6, _toDisplayString(_ctx.$t('nutrition.foodDistribution.real')), 1),
            _createElementVNode("td", _hoisted_7, _toDisplayString(_ctx.totalCalories), 1),
            _createElementVNode("td", _hoisted_8, _toDisplayString(_ctx.totalProtein), 1),
            _createElementVNode("td", _hoisted_9, _toDisplayString(_ctx.totalFat), 1),
            _createElementVNode("td", _hoisted_10, _toDisplayString(_ctx.totalCarbs), 1)
          ]),
          _createElementVNode("tr", null, [
            _createElementVNode("td", _hoisted_11, _toDisplayString(_ctx.$t('nutrition.foodDistribution.theoretical')), 1),
            _createElementVNode("td", null, _toDisplayString(_ctx.nutritionalPlanFormDto.currentCalories), 1),
            _createElementVNode("td", null, _toDisplayString(_ctx.proteinValues.bites), 1),
            _createElementVNode("td", null, _toDisplayString(_ctx.fatValues.bites), 1),
            _createElementVNode("td", null, _toDisplayString(_ctx.carbsValues.bites), 1)
          ])
        ])
      ])
    ])
  ]))
}