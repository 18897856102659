import { Genders } from '@/models/Genders';
import { MacroType } from '@/models/NutritionalPlan';

function calculateMealCalories(totalCalories: number, share: number) {
  return Number(((totalCalories * share) / 100).toFixed(2));
}

function calculateMealQuantity(totalCalories: number, macroType: MacroType) {
  if (macroType === MacroType.Fat) {
    return Number((totalCalories / 9).toFixed(2));
  }
  return Number((totalCalories / 4).toFixed(2));
}

function calculateMealBites(totalQuantity: number): number {
  const bites = totalQuantity / 10;

  return Number((Number((bites / 0.5).toFixed(0)) * 0.5).toFixed(1));
}

function calculateMealGrPerKilo(totalQuantity: number, weight: number) {
  return Number((totalQuantity / weight).toFixed(2));
}

function getMifflinStJeorValue(age: number, gender: Genders, weight: number, height: number) {
  if (!age || !gender || !weight || !height) {
    return 0;
  }

  const weightCalc = weight * 10;
  const heightCalc = height * 6.25;

  const ageCalc = age * 5;

  if (gender == Genders.Male) {
    return weightCalc + heightCalc - ageCalc + 5;
  }

  return weightCalc + heightCalc - ageCalc - 161;
}

export {
  calculateMealCalories,
  calculateMealBites,
  calculateMealQuantity,
  calculateMealGrPerKilo,
  getMifflinStJeorValue,
};
