import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, resolveDirective as _resolveDirective, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, withDirectives as _withDirectives, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, createTextVNode as _createTextVNode, createCommentVNode as _createCommentVNode, createVNode as _createVNode } from "vue"

const _hoisted_1 = { class: "c-plan-container" }
const _hoisted_2 = { class: "c-plan-title" }
const _hoisted_3 = ["aria-label"]
const _hoisted_4 = { class: "c-plan-table-container" }
const _hoisted_5 = ["aria-label"]
const _hoisted_6 = { key: 0 }
const _hoisted_7 = { class: "row-purple" }
const _hoisted_8 = { class: "c-plan-footer" }
const _hoisted_9 = {
  key: 0,
  class: "c-plan-actions"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Button = _resolveComponent("Button")!
  const _component_InputNumber = _resolveComponent("InputNumber")!
  const _component_NutritionalPlanMealConfirmModal = _resolveComponent("NutritionalPlanMealConfirmModal")!
  const _directive_tooltip = _resolveDirective("tooltip")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("h4", null, _toDisplayString(_ctx.mutableMeal.name ?? _ctx.$t(`nutrition.${_ctx.mutableMeal.mealType}`)), 1),
        _withDirectives((_openBlock(), _createBlock(_component_Button, {
          disabled: !_ctx.userCanEdit,
          class: _normalizeClass(["p-button-rounded p-button-only-icon p-button-only-icon--dark", _ctx.editMode ? 'p-disabled' : '']),
          onClick: _ctx.onEditForm
        }, {
          default: _withCtx(() => [
            _createElementVNode("i", {
              class: "icon-edit",
              "aria-label": _ctx.$t('common.edit'),
              "aria-hidden": "true"
            }, null, 8, _hoisted_3)
          ]),
          _: 1
        }, 8, ["disabled", "class", "onClick"])), [
          [
            _directive_tooltip,
            _ctx.$t('common.edit'),
            void 0,
            { bottom: true }
          ]
        ])
      ]),
      _createElementVNode("div", _hoisted_4, [
        _createElementVNode("table", {
          class: "table-plan",
          "aria-label": _ctx.mutableMeal.name
        }, [
          _createElementVNode("thead", null, [
            _createElementVNode("tr", null, [
              _cache[0] || (_cache[0] = _createElementVNode("th", null, null, -1)),
              _createElementVNode("th", null, _toDisplayString(_ctx.$t('nutrition.foodDistribution.distribution')), 1),
              _createElementVNode("th", null, _toDisplayString(_ctx.$t('nutrition.foodDistribution.kcal')), 1),
              _createElementVNode("th", null, _toDisplayString(_ctx.$t('nutrition.foodDistribution.healzy-bites')), 1),
              _createElementVNode("th", null, _toDisplayString(_ctx.$t('nutrition.foodDistribution.gr')), 1)
            ])
          ]),
          _createElementVNode("tbody", null, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.mutableMeal.mealDetails, (mealDetail, mealDetailIndex) => {
              return (_openBlock(), _createElementBlock("tr", { key: mealDetailIndex }, [
                (mealDetail.macroType)
                  ? (_openBlock(), _createElementBlock("td", _hoisted_6, [
                      _createElementVNode("i", {
                        class: _normalizeClass(_ctx.getMacroIcon(mealDetail.macroType))
                      }, null, 2),
                      _createTextVNode(" " + _toDisplayString(_ctx.$t(`nutrition.${mealDetail.macroType}`)), 1)
                    ]))
                  : _createCommentVNode("", true),
                _createElementVNode("td", null, [
                  (_ctx.editMode)
                    ? (_openBlock(), _createBlock(_component_InputNumber, {
                        key: 0,
                        id: mealDetailIndex,
                        modelValue: mealDetail.share,
                        "onUpdate:modelValue": ($event: any) => ((mealDetail.share) = $event),
                        class: _normalizeClass({ 'no-editable': !_ctx.editMode }),
                        "max-fraction-digits": 2,
                        min: 0,
                        max: 100,
                        onInput: ($event: any) => (_ctx.recalculateValues($event, mealDetail.macroType))
                      }, null, 8, ["id", "modelValue", "onUpdate:modelValue", "class", "onInput"]))
                    : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
                        _createTextVNode(_toDisplayString(mealDetail.share) + " % ", 1)
                      ], 64))
                ]),
                _createElementVNode("td", null, _toDisplayString(mealDetail.calories), 1),
                _createElementVNode("td", _hoisted_7, _toDisplayString(mealDetail.bites), 1),
                _createElementVNode("td", null, _toDisplayString(mealDetail.quantity), 1)
              ]))
            }), 128))
          ])
        ], 8, _hoisted_5),
        _createElementVNode("p", {
          class: _normalizeClass(["table-footer", { 'porcentages-invalid': !_ctx.mealPlanCanBeUpdated }])
        }, _toDisplayString(_ctx.$t('nutrition.error-porcentages-invalid')), 3)
      ]),
      _createElementVNode("div", _hoisted_8, [
        _createElementVNode("p", null, [
          _createTextVNode(_toDisplayString(_ctx.$t('nutrition.foodDistribution.total-calories')) + ": ", 1),
          _createElementVNode("span", null, _toDisplayString(`${_ctx.totalCalculatedCalories} ${_ctx.$t('nutrition.foodDistribution.kcal')}`), 1)
        ])
      ]),
      (_ctx.editMode)
        ? (_openBlock(), _createElementBlock("div", _hoisted_9, [
            _createVNode(_component_Button, {
              label: _ctx.$t('common.cancel'),
              class: "p-button p-component p-button-secondary p-button-medium",
              onClick: _ctx.onCancel
            }, null, 8, ["label", "onClick"]),
            _createVNode(_component_Button, {
              label: _ctx.$t('common.save'),
              class: "p-button p-component p-button-info p-button-medium",
              disabled: !_ctx.mealPlanCanBeUpdated,
              onClick: _ctx.onSave
            }, null, 8, ["label", "disabled", "onClick"])
          ]))
        : _createCommentVNode("", true)
    ]),
    _createVNode(_component_NutritionalPlanMealConfirmModal, {
      ref: "updateNutritionPlanMealsCardModalRef",
      "nutritional-plan-id": _ctx.nutritionalPlanFormDto.id,
      "meal-type": _ctx.meal.mealType,
      onUpdatedNutritionalPlanMeal: _ctx.updatedNutritionalPlanMeal
    }, null, 8, ["nutritional-plan-id", "meal-type", "onUpdatedNutritionalPlanMeal"])
  ], 64))
}