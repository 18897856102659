
import { calculateMealBites, calculateMealCalories, calculateMealQuantity } from '@/helpers/NutritionHelper';
import { MacroType, NutritionalPlanFormDto } from '@/models/NutritionalPlan';
import { PropType, computed, defineComponent, onMounted, ref, watch } from 'vue';

export default defineComponent({
  props: {
    nutritionalPlanFormDto: {
      type: Object as PropType<NutritionalPlanFormDto>,
      required: true,
    },
    totalCalories: {
      type: Number,
      required: true,
    },
  },
  setup(props) {
    const totalProtein = ref(0);
    const totalFat = ref(0);
    const totalCarbs = ref(0);

    const proteinValues = computed(() => {
      return calculateMacronutrientValues(
        +props.nutritionalPlanFormDto.currentCalories,
        +props.nutritionalPlanFormDto.proteinShare.toFixed(2),
        MacroType.Protein,
      );
    });

    const carbsValues = computed(() => {
      return calculateMacronutrientValues(
        +props.nutritionalPlanFormDto.currentCalories,
        +props.nutritionalPlanFormDto.carbohydratesShare.toFixed(2),
        MacroType.Carbs,
      );
    });

    const fatValues = computed(() => {
      return calculateMacronutrientValues(
        +props.nutritionalPlanFormDto.currentCalories,
        +props.nutritionalPlanFormDto.fatShare.toFixed(2),
        MacroType.Fat,
      );
    });

    function calculateMacronutrientValues(totalCalories: number, share: number, macroType: MacroType) {
      const calories = ref(calculateMealCalories(totalCalories, share));
      const quantity = ref(calculateMealQuantity(calories.value, macroType));
      const bites = ref(calculateMealBites(quantity.value));

      function recalculateValues(newValue: any) {
        if (!newValue.value) {
          return;
        }

        calories.value = calculateMealCalories(totalCalories, newValue.value);
        quantity.value = calculateMealQuantity(calories.value, macroType);
        bites.value = calculateMealBites(quantity.value);
      }

      return {
        calories,
        quantity,
        bites,
        recalculateValues,
      };
    }

    const calculateValues = () => {
      totalProtein.value = 0;
      totalCarbs.value = 0;
      totalFat.value = 0;

      props.nutritionalPlanFormDto.meals.forEach((meal) => {
        meal.mealDetails.forEach((mealDetails) => {
          if (mealDetails.macroType === MacroType.Protein) {
            totalProtein.value += mealDetails.bites;
          }
          if (mealDetails.macroType === MacroType.Carbs) {
            totalCarbs.value += mealDetails.bites;
          }
          if (mealDetails.macroType === MacroType.Fat) {
            totalFat.value += mealDetails.bites;
          }
        });
      });
    };

    onMounted(() => {
      calculateValues();
    });

    watch(props.nutritionalPlanFormDto, () => {
      calculateValues();
    });

    return {
      totalProtein,
      totalFat,
      totalCarbs,
      proteinValues,
      fatValues,
      carbsValues,
    };
  },
});
