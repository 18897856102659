
import { MealFormDto, MealType } from '@/models/NutritionalPlan';
import { nutritionalPlanService } from '@/services/NutritionalPlanService';
import { ServiceError } from '@/services/util/ServiceError';
import { useToast } from 'primevue/usetoast';
import { defineComponent, PropType, ref } from 'vue';
import { useI18n } from 'vue-i18n';
import { useRoute } from 'vue-router';

export default defineComponent({
  emits: ['updated-nutritional-plan-meal'],
  props: {
    nutritionalPlanId: {
      type: String,
      required: true,
    },
    mealType: {
      type: Object as PropType<MealType>,
      required: true,
    },
  },

  setup(props, { emit }) {
    const { t } = useI18n();
    const toast = useToast();
    const route = useRoute();
    const showDialog = ref(false);
    const submitting = ref(false);

    const patientId = route.params.patientId as string;
    const meal = ref();

    const openDialog = (m: MealFormDto) => {
      if (!m) {
        return;
      }

      showDialog.value = true;

      meal.value = m;
    };

    const onSubmit = async (e: Event, updateMeals: boolean) => {
      e.preventDefault();
      submitting.value = true;

      try {
        const result = await nutritionalPlanService.updateNutritionalPlanMeal(
          patientId,
          props.nutritionalPlanId,
          props.mealType,
          meal.value,
          updateMeals,
        );

        if (!(result instanceof ServiceError)) {
          emit('updated-nutritional-plan-meal', result);
        } else {
          toast.add({
            severity: 'error',
            summary: `${t('messages.notifications.errorEditMeal')} ${t('messages.notifications.tryLater')}`,
            life: 3000,
          });
        }
      } catch (error) {
        toast.add({
          severity: 'error',
          summary: `${t('messages.notifications.errorEditMeal')} ${t('messages.notifications.tryLater')}`,
          life: 3000,
        });
      } finally {
        submitting.value = false;
        showDialog.value = false;
      }
    };

    return {
      submitting,
      showDialog,

      openDialog,
      onSubmit,
    };
  },
});
